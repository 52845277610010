
import { page, status } from '@/api/system/user';
import JtDatePicker from '@/components/JtDatePicker/index.vue';
import JtPagination from '@/components/JtPagination/index.vue';
import { Component,Vue } from 'vue-property-decorator'
import ChangePassword from './components/ChangePassword.vue';
import ChangeUserInfo from './components/ChangeUserInfo.vue';
import UserInfo from './components/UserInfo.vue';

@Component({
    name: 'User',
    components: {
      JtPagination,
      ChangeUserInfo,
      UserInfo,
      ChangePassword,
      JtDatePicker
    }
})
export default class extends Vue {
  dialogUpdateVisible = false
  dialogPasswordVisible = false
  dialogUserInfoVisible = false
  dialogBindPhoneVisible = false
  isEdit = true
  listLoading = true
  total = 0
  infoParams = {}
  infoTitle = ""
  list = []
  listQuery = {
    current: 1,
    size: 10,
    enable: null,
    startTime: '',
    keyword: null,
    endTime: ''
  }
  pickUserId = ''
  mounted() {
      this.getList()
  }
  //初始加载数据
  handleFilter() {
    this.listQuery.current = 1
    this.getList()
  }

  //加载api数据
  getList(){
    this.listLoading = true
    page(this.listQuery).then(response => {
        this.list = response.data.rows
        this.total = response.data.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      }).catch(()=>{
        this.listLoading = false
      })
  }

  handleModifyStatus(row:any){
    this.$confirm('此操作将【' + (row.enable ? '冻结' : '恢复') + '】当前账户, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      status(row).then(response => {
        const { enable } = response.data
        row.enable = enable
        this.$message({
            type: 'success',
            message: '操作成功!'
          });
      }).catch(()=>{})
    }).catch(() => {
      this.$message({
            type: 'info',
            message: '已取消当前操作'
          });      
    });
  }

  handleUpdate(row:any){
    this.isEdit = true
    this.infoParams = row
    this.dialogUpdateVisible = true
  }
  handlePassword(row:any){
    this.infoParams = row
    this.dialogPasswordVisible = true
  }
  handleUserInfo(row:any){
    this.infoParams = row
    this.dialogUserInfoVisible = true
  }

  closeUpdateDialog(){
    this.dialogUpdateVisible = false
  }

  closePasswordDialog(){
    this.dialogPasswordVisible = false
  }

  closeUserInfoDialog(){
    this.dialogUserInfoVisible = false
  }

  handleAddUser(){
    this.isEdit = false
    this.infoParams = {}
    this.dialogUpdateVisible = true
  }
}
