
import { loadAllRoles } from '@/api/system/role';
import { addUser, updateUserInfo } from '@/api/system/user';
import JtAvatarUpload from '@/components/JtAvatarUpload/index.vue';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'ChangeUserInfo',
  components: {
    JtAvatarUpload
  }
})
export default class ChangeUserInfo extends Vue {
  @Prop({
    required: true,
    default: false
  }) readonly dialogUpdateVisible!: boolean;
  @Prop({
    required: false,
    default: false
  }) readonly isEdit!: boolean;
  @Prop({ required: false }) readonly infoParams!: any;

  formParam = {
    id: null,
    userId: null,
    roles: [],
    username: null,
    nickname: null,
    realname: null,
    avatar: "",
    telephone: null,
    sex: null,
    enable: null,
    password: "",
    comfirm: ""
  }

  get infoTitle() {
    if (this.isEdit) {
      return "编辑账户-【" + this.formParam.username + "】";
    } else {
      return "添加账户";
    }
  }

  rolesView: Array<{
    roleCode?: any
    roleName?: any
  }> = []
  rolesCheck = []

  @Emit("getList")
  getList() {
  }

  @Emit("closeUpdateDialog")
  closeUpdateDialog() {
  }

  handleUpdateUserInfo() {
    this.formParam.roles = this.rolesCheck
    if (this.isEdit) {
      updateUserInfo(this.formParam).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.closeUpdateDialog()
        this.getList();
      })
    } else {
      addUser(this.formParam).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.closeUpdateDialog()
        this.getList();
      })
    }
  }

  mounted() {
    this.loadRoles()
  }

  @Watch("infoParams")
  infoParamsChange() {
    this.formParam = this.infoParams
    if (this.infoParams.roles) {
      this.rolesCheck = this.infoParams.roles
    } else {
      this.rolesCheck = []
    }
  }

  loadRoles() {
    loadAllRoles().then(res => {
      this.rolesView = res.data;
    })
  }
}
